
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46pageSlug_93PHbU2fEplsMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/cms/pages/[...pageSlug].vue?macro=true";
import { default as activated1sGDUDC8kMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/account/pages/account/activate.vue?macro=true";
import { default as indexol9inUiGCGMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/account/pages/account/index.vue?macro=true";
import { default as _91securityKey_93NnJ3pOv5WvMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/account/pages/account/order/[orderNumber]/[securityKey].vue?macro=true";
import { default as newRmvWo60ecpMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/account/pages/account/password/new.vue?macro=true";
import { default as watchlistNKtqtPcmtuMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/account/pages/account/watchlist.vue?macro=true";
import { default as index26S5YvaOcFMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/builder/pages/admin/index.vue?macro=true";
import { default as editOj9II2n0eeMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/builder/pages/admin/raffle/[id]/edit.vue?macro=true";
import { default as indexN9UaIarcdcMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/builder/pages/admin/raffle/[id]/index.vue?macro=true";
import { default as add5YYwNEKVeGMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/builder/pages/admin/raffle/add.vue?macro=true";
import { default as rafflesB8BJi5OIGpMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/builder/pages/admin/raffles.vue?macro=true";
import { default as faqi1ZVuLhOXLMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue?macro=true";
import { default as infoVE8P4fatTvMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue?macro=true";
import { default as _91showGroupSlug_93ACpryiT9zwMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue?macro=true";
import { default as programwFV8GEwDVUMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue?macro=true";
import { default as shopGyDo9BZGeDMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue?macro=true";
import { default as _91showSlug_93HvSJP8aQYDMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue?macro=true";
import { default as subscriptionrHWyrkyu5yMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue?macro=true";
import { default as vouchersEK9kpAWMKbMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue?macro=true";
import { default as _91cinemaSlug_93fgcUJiJNqrMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue?macro=true";
import { default as indexqbr5zawsmPMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/index.vue?macro=true";
import { default as cinemastljsr7hEdlMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinemas.vue?macro=true";
import { default as citiesoZeg1gkPP0Meta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cities.vue?macro=true";
import { default as indexNzYZSwMSIxMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/index.vue?macro=true";
import { default as _91movieSlug_93AQuL5TyVVUMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/movie/[movieSlug].vue?macro=true";
import { default as indexk8o2zyPdGxMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/movies/genre/[genreSlug]/index.vue?macro=true";
import { default as _91genreSlug_93bT8Y80TlyeMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/movies/genre/[genreSlug].vue?macro=true";
import { default as genresRzoA3DNhnJMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/movies/genres.vue?macro=true";
import { default as indexqU7sZPrj8hMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/movies/index.vue?macro=true";
import { default as _91personSlug_93hwvNbyF7ptMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/person/[personSlug].vue?macro=true";
import { default as personsETmteZi4JlMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/persons.vue?macro=true";
import { default as searchnJ3fHLzU9DMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/search.vue?macro=true";
import { default as vouchersd5lVtPVjk2Meta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/vouchers.vue?macro=true";
export default [
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/cms/pages/[...pageSlug].vue")
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/cms/pages/[...pageSlug].vue")
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/cms/pages/[...pageSlug].vue")
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/account/pages/account/activate.vue")
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/account/pages/account/activate.vue")
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/account/pages/account/activate.vue")
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/account/pages/account/index.vue")
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/account/pages/account/index.vue")
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/account/pages/account/index.vue")
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber()/:securityKey()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/account/pages/account/order/[orderNumber]/[securityKey].vue")
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber()/:securityKey()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/account/pages/account/order/[orderNumber]/[securityKey].vue")
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber()/:securityKey()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/account/pages/account/order/[orderNumber]/[securityKey].vue")
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/account/pages/account/password/new.vue")
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/account/pages/account/password/new.vue")
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/account/pages/account/password/new.vue")
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/account/pages/account/watchlist.vue")
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/account/pages/account/watchlist.vue")
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/account/pages/account/watchlist.vue")
  },
  {
    name: "admin___de",
    path: "/admin",
    meta: index26S5YvaOcFMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/builder/pages/admin/index.vue")
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: index26S5YvaOcFMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/builder/pages/admin/index.vue")
  },
  {
    name: "admin___es",
    path: "/es/admin",
    meta: index26S5YvaOcFMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/builder/pages/admin/index.vue")
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/admin/raffle/:id()/edit",
    meta: editOj9II2n0eeMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/builder/pages/admin/raffle/[id]/edit.vue")
  },
  {
    name: "admin-raffle-id-edit___en",
    path: "/en/admin/raffle/:id()/edit",
    meta: editOj9II2n0eeMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/builder/pages/admin/raffle/[id]/edit.vue")
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/es/admin/raffle/:id()/edit",
    meta: editOj9II2n0eeMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/builder/pages/admin/raffle/[id]/edit.vue")
  },
  {
    name: "admin-raffle-id___de",
    path: "/admin/raffle/:id",
    meta: indexN9UaIarcdcMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/builder/pages/admin/raffle/[id]/index.vue")
  },
  {
    name: "admin-raffle-id___en",
    path: "/en/admin/raffle/:id",
    meta: indexN9UaIarcdcMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/builder/pages/admin/raffle/[id]/index.vue")
  },
  {
    name: "admin-raffle-id___es",
    path: "/es/admin/raffle/:id",
    meta: indexN9UaIarcdcMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/builder/pages/admin/raffle/[id]/index.vue")
  },
  {
    name: "admin-raffle-add___de",
    path: "/admin/raffle/add",
    meta: add5YYwNEKVeGMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/builder/pages/admin/raffle/add.vue")
  },
  {
    name: "admin-raffle-add___en",
    path: "/en/admin/raffle/add",
    meta: add5YYwNEKVeGMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/builder/pages/admin/raffle/add.vue")
  },
  {
    name: "admin-raffle-add___es",
    path: "/es/admin/raffle/add",
    meta: add5YYwNEKVeGMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/builder/pages/admin/raffle/add.vue")
  },
  {
    name: "admin-raffles___de",
    path: "/admin/raffles",
    meta: rafflesB8BJi5OIGpMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/builder/pages/admin/raffles.vue")
  },
  {
    name: "admin-raffles___en",
    path: "/en/admin/raffles",
    meta: rafflesB8BJi5OIGpMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/builder/pages/admin/raffles.vue")
  },
  {
    name: "admin-raffles___es",
    path: "/es/admin/raffles",
    meta: rafflesB8BJi5OIGpMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/builder/pages/admin/raffles.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "/kino/:citySlug()/:cinemaSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue"),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "info",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "film/:showGroupSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "vorstellungen",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "vorstellung/:showSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-subscription___de",
    path: "abo",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue")
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug___en",
    path: "/en/cinema/:citySlug()/:cinemaSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue"),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___en",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___en",
    path: "info",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___en",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___en",
    path: "program",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___en",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___en",
    path: "show/:showSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-subscription___en",
    path: "subscription",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___en",
    path: "vouchers",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue")
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "/es/cine/:citySlug()/:cinemaSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue"),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "informacion",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "sesiones",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "evento/:showSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-subscription___es",
    path: "suscripcion",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "codigos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue")
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "/cinema/:citySlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/index.vue")
  },
  {
    name: "cinema-citySlug___en",
    path: "/en/cinema/:citySlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/index.vue")
  },
  {
    name: "cinema-citySlug___es",
    path: "/es/cinema/:citySlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinema/[citySlug]/index.vue")
  },
  {
    name: "cinemas___de",
    path: "/kinos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinemas.vue")
  },
  {
    name: "cinemas___en",
    path: "/en/cinemas",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinemas.vue")
  },
  {
    name: "cinemas___es",
    path: "/es/cines",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cinemas.vue")
  },
  {
    name: "cities___de",
    path: "/staedte",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cities.vue")
  },
  {
    name: "cities___en",
    path: "/en/cities",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cities.vue")
  },
  {
    name: "cities___es",
    path: "/es/ciudades",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/cities.vue")
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/index.vue")
  },
  {
    name: "movie-movieSlug___de",
    path: "/film/:movieSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/movie/[movieSlug].vue")
  },
  {
    name: "movie-movieSlug___en",
    path: "/en/movie/:movieSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/movie/[movieSlug].vue")
  },
  {
    name: "movie-movieSlug___es",
    path: "/es/pelicula/:movieSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/movie/[movieSlug].vue")
  },
  {
    name: _91genreSlug_93bT8Y80TlyeMeta?.name,
    path: "/filme/genre/:genreSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/movies/genre/[genreSlug].vue"),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/movies/genre/[genreSlug]/index.vue")
  }
]
  },
  {
    name: _91genreSlug_93bT8Y80TlyeMeta?.name,
    path: "/en/movies/genre/:genreSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/movies/genre/[genreSlug].vue"),
    children: [
  {
    name: "movies-genre-genreSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/movies/genre/[genreSlug]/index.vue")
  }
]
  },
  {
    name: _91genreSlug_93bT8Y80TlyeMeta?.name,
    path: "/es/peliculas/generos/:genreSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/movies/genre/[genreSlug].vue"),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/movies/genre/[genreSlug]/index.vue")
  }
]
  },
  {
    name: "movies-genres___de",
    path: "/filme/genres",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/movies/genres.vue")
  },
  {
    name: "movies-genres___en",
    path: "/en/movies/genres",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/movies/genres.vue")
  },
  {
    name: "movies-genres___es",
    path: "/es/peliculas/generos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/movies/genres.vue")
  },
  {
    name: "movies___de",
    path: "/filme",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/movies/index.vue")
  },
  {
    name: "movies___en",
    path: "/en/movies",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/movies/index.vue")
  },
  {
    name: "movies___es",
    path: "/es/peliculas",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/movies/index.vue")
  },
  {
    name: "person-personSlug___de",
    path: "/person/:personSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/person/[personSlug].vue")
  },
  {
    name: "person-personSlug___en",
    path: "/en/person/:personSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/person/[personSlug].vue")
  },
  {
    name: "person-personSlug___es",
    path: "/es/persona/:personSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/person/[personSlug].vue")
  },
  {
    name: "persons___de",
    path: "/personen",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/persons.vue")
  },
  {
    name: "persons___en",
    path: "/en/persons",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/persons.vue")
  },
  {
    name: "persons___es",
    path: "/es/personas",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/persons.vue")
  },
  {
    name: "search___de",
    path: "/suche",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/search.vue")
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/search.vue")
  },
  {
    name: "search___es",
    path: "/es/buscar",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/search.vue")
  },
  {
    name: "vouchers___de",
    path: "/gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/vouchers.vue")
  },
  {
    name: "vouchers___en",
    path: "/en/vouchers",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/vouchers.vue")
  },
  {
    name: "vouchers___es",
    path: "/es/codigos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_07-09-44_3f1517d/layers/portal/pages/vouchers.vue")
  }
]